import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { SiteInductionInfo } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalizationService } from 'src/app/components/internationalization/localization.service';
import { SiteService } from 'src/app/services/site/site.service';
import { Site } from 'src/app/models/site.model';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { FileStorageService } from 'src/app/services/filestorage/filestorage.service';
import { ConfigurationService } from '@app/services/configuration/configuration.service';
import { FormService } from '@app/services/form/form.service';
import { cityOnSiteProfileQnA, ComplianceCheckAnswerModel, InductionQnA } from '@app/models/inductionqna.model';
import { CityComplianceStatus, CityWorkType, CompanyType, DefaultCompanyType, DisplayedComplianceStatus, JobType } from '@app/shared/enums';
import { SiteVisitReason } from '@app/models/sitevisitreason.model';
import { JobService } from '@app/services/job/job.service';
import { Job } from '@app/models/job.model';

@Component({
  selector: 'app-cityonsiteprofile',
  templateUrl: './cityonsiteprofile.component.html',
  styleUrls: ['./cityonsiteprofile.component.scss']
})

export class CityonsiteprofileComponent implements OnInit {
  currentUser: User | undefined;
  profileImgLink: string | undefined;
  public siteInductionInfoList: SiteInductionInfo[] = [];
  displayfullname: string | undefined;
  displayinductionno: string | undefined;
  displayinductionexpirydate: string | undefined;
  currentSite: Site | undefined;
  permit: string[] = [];
  public siteInductionInfo: SiteInductionInfo[] = [];
  profileInductionModuleEnable = false;
  profileRapidGlobalRequired = false;
  objCompliance: ComplianceCheckAnswerModel | undefined;
  defaultNoCompanySpecified: string = DefaultCompanyType.NO_COMPANY_SPECIFIED;
  defaultCityFMCompanyName: string = DefaultCompanyType.CITYFM;
  enumCityComplianceStatus = CityComplianceStatus;
  permitQnA: InductionQnA;

  statusCompliant = this._localizationService.translate(DisplayedComplianceStatus.statusCompliant);
  statusNotCompliant = this._localizationService.translate(DisplayedComplianceStatus.statusNotCompliant);
  statusNotApplicable = this._localizationService.translate(DisplayedComplianceStatus.statusNotApplicable);

  @Input('question') question: any = {};

  cityOnsiteProfileQnAObjects: cityOnSiteProfileQnA[] = [];
  cityOnsiteProfileQnAString: string;

  visitSignOffQnA: cityOnSiteProfileQnA = {
    question: this._localizationService.translate('site_member_validation_lbl_visit_sign_off_question'),
    answer: undefined,
    isRequired: true
  }

  siteVisitReasons: SiteVisitReason[] = [];
  userCompanies: string[] = [];
  allocatedJobs: Job[] = [];
  nonCityFMWork: string = CityWorkType.NONCITYWORK;
  nonCityFMWorkLabel: string | undefined;

  constructor(private _authService: AuthService,
    private _siteService: SiteService,
    public _router: Router,
    private _localizationService: LocalizationService,
    public datepipe: DatePipe,
    private _fileStorageService: FileStorageService,
    private _configurationService: ConfigurationService,
    private _formService: FormService,
    private _jobService: JobService) {

  }

  async ngOnInit() {
    // Here we set the question.question.isRequired to true 
    // Purpose is to make sure that this page is always required because of the validation check under the CityOnsiteProfile answer 
    // Affected Component: Induction.component.ts
    if (this.question && this.question.question) {
      this.question.question.isRequired = true;
    }

    this.getAnswerForVisitSignOffQuestions();

    this.profileInductionModuleEnable = await this._configurationService.getProfileInductionRequired();
    this.profileRapidGlobalRequired = await this._configurationService.getProfileRapidGlobalRequired();
    this.permit = await this._configurationService.getPermitKey();
    this.nonCityFMWorkLabel = this._localizationService.translate("reasonforvisit_lbl_noncityfmwork");

    await this.loadSite();
    await this.loadComplianceCheck();
    await this.loadPermitInfo();

    var user = await this._authService.getUser()
    if (user) {
      this.currentUser = user;
      this.displayfullname = `${user.firstName} ${user.lastName}`;
      this.getProfileImageLink(user.photo);
      this.siteInductionInfoList = user.siteInductionInfos;
      this.siteInductionInfo = this.siteInductionInfoList.filter(sii => sii.brandId === this.currentSite?.brand?.id);
      if (this.siteInductionInfo.length == 1) {
        this.displayinductionno = "#" + this.siteInductionInfo[0].inductionId;
        this.displayinductionexpirydate = this.datepipe.transform(this.siteInductionInfo[0].expiryDate, 'dd MMMM YYYY')!
      }
      else {
        this.displayinductionno = "";
        this.displayinductionexpirydate = "";
      }
    }
  }

  async loadComplianceCheck() {
    if (this.profileRapidGlobalRequired) {
      const inductionForm = await this._formService.getInductionConfig();
      const complianceCheckQnAs = inductionForm.inductionConfigDetails.filter(i => i.type.toLowerCase() == "compliancecheck");

      if (complianceCheckQnAs && complianceCheckQnAs.length > 0) {
        if (complianceCheckQnAs[0]?.answer) {
          this.objCompliance = JSON.parse(complianceCheckQnAs[0]?.answer) as ComplianceCheckAnswerModel;
        }
      }
    }
  }

  async loadPermitInfo() {
    this.permitQnA = await this._formService.getPermitQnA();
  }

  async loadSite() {
    var site = await this._siteService.getSignedInSite();
    if (site) {
      this.currentSite = site;
      await this.getSiteVisitReasons();
      this.getUserCompanies();
    } else {
      this._siteService.removeSignedInSite();
      this._router.navigate(['signin']);
    }
  }

  getCompliantStatus(cityComplianceStatus: number | undefined): string {
    var status = "";
    if (cityComplianceStatus) {
      switch (cityComplianceStatus) {
        case CityComplianceStatus.COMPLIANT:
          status = this.statusCompliant;
          break;
        case CityComplianceStatus.NOTCOMPLIANT:
          status = this.statusNotCompliant;
          break;
        case CityComplianceStatus.NOTAPPLICABLE:
          status = this.statusNotApplicable;
          break;
        default:
      }
    }

    return status;
  }

  private getProfileImageLink(userPhoto: string) {
    if (environment.isDemo) {
      this.profileImgLink = userPhoto;
    } else {
      // Regular expression to check if string is a valid UUID
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      if (!regexExp.test(userPhoto)) {
        this.profileImgLink = userPhoto;
      } else {
        if (this.currentUser) {
          this._fileStorageService.download(userPhoto).subscribe(
            {
              next: r => {
                this.profileImgLink = `data:image/${r.fileType};base64,${r.file}`;
                if (this.currentUser) {
                  this.currentUser.photo = this.profileImgLink;
                  this._authService.setUser(this.currentUser);
                }

              },
              error: e => {
                this.profileImgLink = "";
              }
            });
        } else {
          this.profileImgLink = "";
        }
      }
    }
  }

  getAnswerForVisitSignOffQuestions() {
    if (this.question.answer) {
      this.cityOnsiteProfileQnAObjects = JSON.parse(this.question.answer);

      for (var obj of this.cityOnsiteProfileQnAObjects) {
        if (obj.question === this.visitSignOffQnA.question) {
          this.visitSignOffQnA.answer = obj.answer;
        }
      }

    } else {
      this.cityOnsiteProfileQnAObjects.push(this.visitSignOffQnA);
      this.question.answer = JSON.stringify(this.cityOnsiteProfileQnAObjects);
    }
  }

  onClickVisitSignOff(value: string) {
    this.onSubmitAnswer(this.visitSignOffQnA.question, value);
  }

  onSubmitAnswer(question: string, answer: string): void {

    switch (question) {
      case this.visitSignOffQnA.question:
        this.cityOnsiteProfileQnAObjects = this.cityOnsiteProfileQnAObjects
          .filter(qna => qna.question !== this.visitSignOffQnA.question);
        this.visitSignOffQnA.answer = answer;
        this.cityOnsiteProfileQnAObjects.push(this.visitSignOffQnA);
        break;
      default:
        break;
    }

    const questionAnswerInJsonString = JSON.stringify(this.cityOnsiteProfileQnAObjects);
    this.question.answer = questionAnswerInJsonString;
  }

  async getSiteVisitReasons() {
    this.siteVisitReasons = await this._siteService.getVisitReasons();
    if (this.siteVisitReasons && this.siteVisitReasons.length > 0 ) {
      if (this.siteVisitReasons[0].id !== '') {
        this.getJobsByReasons();
      } else {
        this.checkNonCityJobsDisplayValue(this.siteVisitReasons);
      }
    }
  }

  private getUserCompanies() {
    if (this.currentUser?.companies) {
      this.currentUser.companies.forEach(company => {
        if (company.companyType.toUpperCase() != CompanyType.SUBCON) {
          this.userCompanies.push(company.referenceNumber);
        }
      });
    }
  }

  private getJobsByReasons() {

    this._jobService.getJobsBySelectedReasons(this.siteVisitReasons.map((s) => s.id)).subscribe(
      jobs => {
        if (jobs) {
          jobs.forEach(job => {
            var siteVisitReason = this.siteVisitReasons.find(x => x.id === job.referenceNumber);
            if (siteVisitReason?.id == job.referenceNumber) {
              job.reasonForVisitCheck = true;
              if ((job.allocatedUserEmail) || (this.userCompanies?.some(x => x == job.companyReferenceNumber))) {
                this.allocatedJobs.push(job);
              }
            }
          })
        }
      })
  }

  private checkNonCityJobsDisplayValue(reasons: SiteVisitReason[]) {
    reasons.forEach(reason => {
      if (reason.calloutReferenceNumber === this.nonCityFMWork) {
        reason.calloutReferenceNumber = this.nonCityFMWorkLabel!;
      }
    });
  }

  isJobReason(): boolean {
    if (this.siteVisitReasons && this.siteVisitReasons.length > 0 && this.siteVisitReasons[0].id !== '') {
      return true;
    }
    return false;
  }

  isReactiveJob(job: Job) {
    return (job.jobType == JobType.REACTIVE_JOB) ? true : false;
  }

  isPPMJob(job: Job) {
    return (job.jobType == JobType.PPM_JOB) ? true : false;
  }

}
