import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Brand } from '@app/models/brand.model';
import { VisitorType } from '@app/models/visitortype.model';
import { LocalizationService } from '../internationalization/localization.service';
import { FormControl } from '@angular/forms';
import { ReferenceService } from '@app/services/reference/reference.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VisitorTypeManagementDeleteComponent } from '../visitor-type-management-delete/visitor-type-management-delete.component';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-visitor-type-management',
  templateUrl: './visitor-type-management.component.html',
  styleUrls: ['./visitor-type-management.component.scss'],
})
export class VisitorTypeManagementComponent implements OnInit, AfterViewInit {
  visitorTypes: VisitorType[] = [];
  brands: Brand[] = [];
  editToolTip: string;
  deleteToolTip: string;
  displayedVisitorTypeColumns: string[] = ['Description', 'Brand', 'Domain', 'Actions'];
  searchControl: FormControl = new FormControl('');
  debounce: number = 1000;

  dataSource = new MatTableDataSource<VisitorType>(this.visitorTypes);
  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort;
  pageSize: number = 10;
  totalItems: number = 0;

  selectAllBrandsOption: Brand = { id: 'all', code: '', name: 'All', imageUrl: '', isActive: true, sites: [] };
  selectedBrand: Brand = this.selectAllBrandsOption;
  searchValue: string = '';

  constructor(
    private _router: Router,
    private _localizationService: LocalizationService,
    private _referenceService: ReferenceService,
    private _addFileDialog: MatDialog,
    private _navigationService: NavigationService,
  ) {
    this._navigationService.onChange(true, false);
    this.editToolTip = this._localizationService.translate("visitor_type_management_visitor_type_edit_tooltip");
    this.deleteToolTip = this._localizationService.translate("visitor_type_management_visitor_type_delete_tooltip");
  }

  async ngOnInit(): Promise<void> {
    await this.loadVisitorTypes();
    this.closeSearch();
    this.searchValues();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  searchValues() {
    this.searchControl.valueChanges
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(query => {
        if (query && query.length > 2) {
          let queryLower = query.toLowerCase();
          this.searchValue = queryLower;

          if (this.selectedBrand.id !== this.selectAllBrandsOption.id) {
            this.dataSource.data = this.visitorTypes.filter(vt => vt.brand?.id == this.selectedBrand.id);
          }

          this.dataSource.data = this.dataSource.data.filter(vt =>
            vt.description.toLowerCase().includes(queryLower) ||
            vt.brand!.name.toLocaleLowerCase().includes(queryLower) ||
            (vt.domain && vt.domain.name && vt.domain.name.toLocaleLowerCase().includes(queryLower))
          );
        } else {
          this.searchValue = '';
          this.dataSource.data = this.visitorTypes;
          this.selectBrand(this.selectedBrand?.id);
        }
        this.loadBrands(this.dataSource.data);
      });
  }

  async loadVisitorTypes() {
    this._referenceService.getAllVisitorTypes(true).subscribe({
      next: (visitorTypes) => {
        if (visitorTypes !== null) {
          this.visitorTypes = visitorTypes;
          this.dataSource.data = visitorTypes;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.totalItems = this.dataSource.data.length;
          this.loadBrands(visitorTypes);
        }
      },
      error: (error) => {
        console.error(this._localizationService.translate("visitor_type_management_error_fetching_visitor_types"), error);
      }
    });
  }

  loadBrands(visitorTypes: VisitorType[]): void {
    const allBrands: Brand[] = visitorTypes
      .map(vt => vt.brand)
      .filter((brand): brand is Brand => !!brand);

    this.brands = allBrands.filter((brand, index, self) =>
      index === self.findIndex(b => b.name === brand.name)
    );

    this.brands.unshift(this.selectAllBrandsOption);
  }

  addNewVisitorType(): void {
    this._router.navigate(['/visitortypeaddeditmanagement']);
  }

  editVisitorType(visitorType: VisitorType): void {
    this._router.navigate(['/visitortypeaddeditmanagement', {
      visitorTypeId: visitorType.id,
    }]);
  }

  deleteVisitorType(visitorType: VisitorType): void {
    let deleteVisitorType: MatDialogRef<VisitorTypeManagementDeleteComponent>;
    const dialogConfig = {
      data: {
        type: visitorType
      },
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass: 'details-container'
    };
    deleteVisitorType = this._addFileDialog.open(VisitorTypeManagementDeleteComponent, dialogConfig);
    deleteVisitorType.addPanelClass('details-container');
    deleteVisitorType.afterClosed().subscribe((result) => {
      if (result) {
        this.loadVisitorTypes();
        this.closeSearch();
        alert(this._localizationService.translate("visitor_type_management_delete_snackbar_deleted_msg"));
      }
    });
  }

  selectBrand(brandId: string) {
    if (brandId) {
      if (brandId === this.selectAllBrandsOption.id) {
        this.dataSource.data = this.visitorTypes;
        this.loadBrands(this.dataSource.data);
      } else {
        this.dataSource.data = this.visitorTypes.filter(vt =>
          vt.brand!.id.toLocaleLowerCase().includes(brandId)
        );
      }

      if (this.searchValue !== '') {
        this.dataSource.data = this.dataSource.data.filter(vt =>
          vt.description.toLocaleLowerCase().includes(this.searchValue) ||
          vt.domain && vt.domain.name && vt.domain.name.toLocaleLowerCase().includes(this.searchValue))
      }
    } else {
      this.dataSource.data = this.visitorTypes;
    }
    this.selectedBrand = this.brands.find(b => b.id == brandId)!;
  }

  onSortChange(event: any) {
    if (!event.active || event.direction === '') {
      return;
    }

    this.dataSource.data = this.dataSource.data.slice().sort((a, b) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'Description': return this.compare(a.description || '', b.description || '', isAsc);
        case 'Brand': return this.compare(a.brand?.name || '', b.brand?.name || '', isAsc);
        case 'Domain': return this.compare(a.domain?.name || '', b.domain?.name || '', isAsc);
        default: return 0;
      }
    });
  }

  compare(a: string | number | boolean, b: string | number | boolean, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  closeVisitorTypeManagement(): void {
    this._router.navigate(['signin']);
  }

  openSearch() {
    this.searchControl.setValue('');
  };

  closeSearch() {
    this.searchControl.setValue(null);
    this.searchValue = '';
    this.selectBrand(this.selectAllBrandsOption.id);
  };

  showSearchButton() {
    return this.searchControl.value === null;
  };

  showSearchInput() {
    return this.searchControl.value !== null;
  };

  onSearchInputKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

}