import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { User, Module, SignInSignOut, SignInInfo } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { SiteService } from 'src/app/services/site/site.service';
import { UserService } from 'src/app/services/user/user.service';
import { LocalizationService } from '../internationalization/localization.service';
import { CityWorkType, CompanyType, FastLinkType, JobType, PermitStatus } from 'src/app/shared/enums';
import { JobService } from 'src/app/services/job/job.service';
import { ClosedownService } from '../../services/closedown/closedown.service';
import { FormService } from 'src/app/services/form/form.service';
import { InductionConfig } from 'src/app/models/inductionconfig.model';
import { Site } from 'src/app/models/site.model';
import { NotificationPushService } from 'src/app/services/notification/notification.push.service';
import { UtilsService } from 'src/app/services/util/util-service';
import { ComplianceCheckAnswerModel } from '@app/models/inductionqna.model';
import { SiteVisitReason } from '@app/models/sitevisitreason.model';
import { LoaderService } from '@app/services/loader/loader.service';
import { Browser } from '@capacitor/browser';
import { GeolocationService } from '@app/services/geolocation/geolocation.service';
import { ConfigurationService } from '@app/services/configuration/configuration.service';
import { PermitService } from '@app/services/permit/permit-service';
import { Job } from '@app/models/job.model';
import { VisitSummaryJob } from '@app/models/visitsummaryjob.model';

@Component({
    selector: 'app-site-induction-completed',
    templateUrl: './site-induction-completed.component.html',
    styleUrls: ['./site-induction-completed.component.scss']
})
export class SiteInductionCompletedComponent implements OnInit {
    private unsubscriberBackBtn: Subject<void> = new Subject<void>();
    induction: string = 'INDUCTION';
    nonInduction: string = 'NONINDUCTION';
    currentUser: User | undefined;
    fullname: string | undefined;
    bearerToken: string | undefined;
    allModules: Module[] = [];
    distinctModules: Module[] = [];
    inductionConfig: InductionConfig | undefined;
    selectedSite: Site | undefined;
    permit: string[] = [];
    permitStatus: number = PermitStatus.DISABLED;
    permitStatusEnum = PermitStatus;
    signIn: boolean = false;
    signInExistsMatchString: string = 'Sign In info already exists';
    outOfRange: boolean = false;
    siteVisitReasons: SiteVisitReason[] = [];
    showWorkBench: boolean = false;
    showMyJobs: boolean = false;
    showProductLabel: boolean = false;
    allocatedJobs: VisitSummaryJob[] = [];
    userCompanies: string[] = [];
    nonCityFMWork: string = CityWorkType.NONCITYWORK;
    nonCityFMWorkLabel: string | undefined;

    constructor(private _authService: AuthService,
        private _siteService: SiteService,
        public _router: Router,
        private _userService: UserService,
        private _navigationService: NavigationService,
        private _localizationService: LocalizationService,
        private _closedownService: ClosedownService,
        private _formService: FormService,
        private _jobService: JobService,
        private _notificationPushService: NotificationPushService,
        private _utilsService: UtilsService,
        private _loaderService: LoaderService,
        private _geolocationService: GeolocationService,
        private _configurationService: ConfigurationService,
        private _permitService: PermitService
    ) {
        this._navigationService.onChange(true, false);
        this.initBrowserBackButton();
    }

    ngOnInit(): void {
        this.nonCityFMWorkLabel = this._localizationService.translate("reasonforvisit_lbl_noncityfmwork");
        this.initRedirection();
    }

    initRedirection(): void {

        this._closedownService.getInductionFirstRedirect().then(
            async result => {
                if (result == 'true') {
                    this.signIn = true;
                }

                this.allModules = await this._authService.getUserModule();
                if (this.allModules) {
                    this.distinctModules = this.allModules.filter(s => s.isIntegrationModule == true);
                }

                this.permit = await this._configurationService.getPermitKey();
                this.outOfRange = (await this._userService.getOutOfRange()) == 'true';

                this._authService.getTokenFromStorage().then(async bearer => {
                    await this._closedownService.setBearerToken(bearer);

                    this._authService.getUser().then(
                        async user => {
                            this.currentUser = user;
                            await this._closedownService.setCurrentUser(user);

                            this.fullname = `${user.firstName} ${user.lastName}`;
                            this.loadSite();
                        });
                });

            }
        );
    }

    loadSite(): void {
        this._siteService.getSignedInSite().then(async store => {
            this.selectedSite = store;
            var processFlow = await this._closedownService.getProcessFlow();
            processFlow.siteCode = store?.code;
            await this._closedownService.setProcessFlow(processFlow);

            if (this.signIn) {
                this.loadInductionConfig();
            }
            else {
                this.checkPermitStatus();
                this.processSiteVisitReasons();
                if (this.selectedSite && !this.outOfRange) {
                    await this._notificationPushService.startLocationWatch(this.selectedSite);
                }
            }
        });
    }

    loadInductionConfig(): void {
        this._formService.getInductionConfig()
            .then(form => {
                if (form) {
                    this.inductionConfig = form;
                }
                this.postSignIn();
            });
    }

    async postSignIn() {
        if (this.currentUser && this.selectedSite) {
            let visitorType = "";
            let reasonforvisit = "";
            let compliancecheck = "";
            let indcutionQnA = "";
            let userDocumentIds: string[] = [];
            if (this.inductionConfig) {
                visitorType = this.inductionConfig.visitorType;
                this.inductionConfig.inductionConfigDetails.forEach(details => {
                    switch (details.type.toLowerCase()) {
                        case "reasonforvisit": {
                            if (details.answer && details.answer.length > 0) {
                                reasonforvisit = details.answer;
                                this._siteService.setReasonForVisit(reasonforvisit);
                            }
                            break;
                        }
                        case "compliancecheck": {
                            if (details.answer && details.answer.length > 0) {
                                compliancecheck = details.answer;
                            }
                            break;
                        }
                        default:
                            if (details?.userDocumentIds && details?.userDocumentIds.length > 0) {
                                userDocumentIds = [...details?.userDocumentIds];
                            }

                            break;
                    }
                });
                indcutionQnA = JSON.stringify(this.inductionConfig);
            }

            var compliaceCompany = "";
            var complianceCompanyRapidGlobalId = "";
            var inductionId = "";
            var inductionGuid = "";
            var rapidGlobalGuid = "";
            var rapidGlobalGuid = "";
            var statusId = -1;
            if (compliancecheck) {
                var objCompliance = JSON.parse(compliancecheck) as ComplianceCheckAnswerModel;
                compliaceCompany = objCompliance.companyName;
                complianceCompanyRapidGlobalId = objCompliance.rapidGlobalId;
                inductionId = objCompliance.inductionNo;
                inductionGuid = objCompliance.inductionGuid;
                rapidGlobalGuid = objCompliance.rapidGlobalGuid;
                statusId = objCompliance.statusId;
            }

            userDocumentIds = userDocumentIds.filter((item, index, self) => self.indexOf(item) === index);
            let localStorageSignInMethod = await this._userService.getSignInMethod();
            let postSignIn: SignInSignOut = {
                id: undefined,
                userId: this.currentUser.id,
                signInDateTime: new Date(),
                signOutDateTime: new Date(0),
                signInType: (this.selectedSite.isInductionRequired) ? this.induction : this.nonInduction,
                siteId: this.selectedSite.id,
                inductionQnA: this._utilsService.encodeBase64String(indcutionQnA),
                visitorType: visitorType,
                reasonForVisit: this._utilsService.encodeBase64String(reasonforvisit),
                complianceCompany: compliaceCompany,
                complianceCompanyRapidGlobalId: complianceCompanyRapidGlobalId,
                inductionId: inductionId,
                signOutSignature: "",
                inductionGuid: inductionGuid,
                companyRapidGlobalGuid: rapidGlobalGuid,
                userDocumentIds: userDocumentIds,
                complianceCompanyRapidGlobalStatus: statusId,
                signInMethod: localStorageSignInMethod
            }
            await this._userService.removeSignInMethod();

            let latestLocation = this._geolocationService.getLatestLocation();
            if (latestLocation && latestLocation.coords.latitude && latestLocation.coords.longitude) {
                postSignIn.SignInLatLon = latestLocation.coords.latitude + "," + latestLocation.coords.longitude;
            }

            this._loaderService.showPageLoading();
            this._userService.addSignIn(postSignIn).subscribe({
                next: async (success) => {
                    let signInInfoSetInLocal: SignInInfo = {
                        signInSignOutId: success.id,
                        siteId: success.siteId,
                        complianceCompany: success.complianceCompany
                    }

                    await this._closedownService.removeInductionFirstRedirect();
                    await this._userService.setSignIn(signInInfoSetInLocal);

                    const permitQnA = await this._formService.getPermitQnA();
                    await this._formService.removePermitQnA();

                    this._formService.removeInductionConfig()
                        .then(async _ => {
                            if (this.selectedSite) {
                                await this._notificationPushService.startLocationWatch(this.selectedSite);
                            }
                            await this.processSiteVisitReasons();
                            if (permitQnA && permitQnA.answer == this.permit[1]) {
                                this._router.navigate(['openpermit']);
                            } else {
                                this.checkPermitStatus();
                            }

                            this._loaderService.hidePageLoading();
                        });
                },
                error: async (reject) => {
                    this._loaderService.hidePageLoading();
                    await this._closedownService.removeInductionFirstRedirect();
                    await this._formService.removePermitQnA();
                    await this._formService.removeInductionConfig();

                    if (reject.error) {
                        var errorMessage = reject.error;
                        if (errorMessage.toLowerCase().indexOf(this.signInExistsMatchString.toLowerCase()) > -1) {
                            alert(this._localizationService.translate("siteinductioncompleted_error_sign_in_info_exist"));
                            let signInInfoSetInLocal: SignInInfo = {
                                signInSignOutId: this.currentUser?.id,
                                siteId: "",
                                complianceCompany: ""
                            }
                            await this._userService.setSignIn(signInInfoSetInLocal);
                            await this.processSiteVisitReasons();
                            this.checkPermitStatus();
                        }
                    }
                    else {
                        alert(this._localizationService.translate("siteinductioncompleted_error_save_sign_in_info"));
                        this._router.navigate(['signin']);
                    }
                }
            })
        }
    }

    reloadWhenBrowserClose() {
        if (this._utilsService.isMobileAndTablet()) {
            Browser.addListener('browserFinished', () => {
                Browser.removeAllListeners().then(s => { });
                this._loaderService.hidePageLoading();
            });
        }
    }

    siteSignOutOnClick() {
        this._router.navigate(['sitesignout']);
    }

    openPermitOnClick() {
        if (this.permitStatus == PermitStatus.NEW) {
            if (confirm(this._localizationService.translate("siteinductioncompleted_confirm_create_permit"))) {
                this._router.navigate(['openpermit']);
            }
        } else if (this.permitStatus == PermitStatus.DRAFT) {
            this._router.navigate(['openpermit']);
        }
    }

    permitWelfareOnClick() {
        if (this.permitStatus == PermitStatus.SUMBITTED) {
            this._router.navigate(['permitwelfarechecks']);
        }
    }

    closePermitOnClick() {
        if (this.permitStatus == PermitStatus.SUMBITTED) {
            this._router.navigate(['closepermit']);
        }
    }

    checkPermitStatus() {
        if (this.selectedSite?.isPermitRequired) {
            this._userService.getSignIn().then(signInInfo => {
                if (signInInfo !== undefined && signInInfo.signInSignOutId !== "") {
                    this._permitService.getPermitBySignInSignOutId(signInInfo.signInSignOutId).subscribe({
                        next: (permit) => {
                            if (permit) {
                                this.permitStatus = permit.status;
                            } else {
                                this.permitStatus = PermitStatus.NEW;
                            }
                        }, error: (error) => {
                            console.error(this._localizationService.translate('close_permit_fetching_permit_error', error));
                        }
                    });
                }
                else {
                    alert(this._localizationService.translate('open_permit_no_signin_found'));
                    this._router.navigate(['signin']);
                }
            });
        } else {
            this.permitStatus = PermitStatus.NA;
        }
    }

    async processSiteVisitReasons() {
        this.siteVisitReasons = await this._siteService.getVisitReasons();
        if (this.siteVisitReasons && this.siteVisitReasons.length > 0) {
            if (this.currentUser) {
                if (this.currentUser.companies) {
                    this.currentUser.companies.forEach(company => {
                        if (company.companyType.toUpperCase() != CompanyType.SUBCON) {
                            this.userCompanies.push(company.referenceNumber);
                        }
                    });
                }

                if (this.siteVisitReasons[0].id !== '') {
                    this.getJobsByReasons();
                } else {
                    this.checkNonCityJobsDisplayValue(this.siteVisitReasons);
                }
            }
        }
        else {
            this.showWorkBench = false;
        }
    }
    
    private checkNonCityJobsDisplayValue(reasons: SiteVisitReason[]) {
        this.showWorkBench = false;
        reasons.forEach(reason => {
            if (reason.calloutReferenceNumber === this.nonCityFMWork) {
                reason.calloutReferenceNumber = this.nonCityFMWorkLabel!;
            }
        });
    }

    private getJobsByReasons() {
        this._jobService.getJobsBySelectedReasons(this.siteVisitReasons.map((s) => s.id)).subscribe(
            jobs => {
                if (jobs) {
                    this._closedownService.setUserJobs(jobs);
                    this.getAllocatedJobs(this.userCompanies, jobs);
                    this.isShowWorkBench(this.userCompanies, jobs);
                }
            });
    }

    private getAllocatedJobs(userCompanies: string[], jobs: Job[]) {
        jobs.forEach(job => {
            var siteVisitReason = this.siteVisitReasons.find(x => x.id === job.referenceNumber);
            if (siteVisitReason?.id == job.referenceNumber) {
                if ((job.allocatedUserEmail) || (userCompanies?.some(x => x == job.companyReferenceNumber))) {

                    var vsj: VisitSummaryJob = {
                        job: job,
                        imageUrl: "",
                        description: "",
                        resourceId: job.companyReferenceNumber,
                        jobStatus: (siteVisitReason.calloutReferenceNumber != "" && siteVisitReason.version != job.version && job.jobStatusName?.toLowerCase() != "callout") ? job.jobStatusName : "Not Updated",
                        updated: true,
                        fastLinkEnabled: (userCompanies?.some(x => x == job.companyReferenceNumber)) || (job.allocatedUserEmail?.toLowerCase() == this.currentUser?.emailAddress.toLowerCase())
                    }

                    this.allocatedJobs.push(vsj);
                }
            }
        })
    }

    private isShowWorkBench(userCompanies: string[], jobs: Job[]) {
        for (const job of jobs) {
            if ((userCompanies?.some(x => x == job.companyReferenceNumber)) || (job.allocatedUserEmail?.toLowerCase() == this.currentUser?.emailAddress.toLowerCase())) {
                this.showWorkBench = true;
                return;
            }
        }
    }

    async moduleOnFirstRedirect(module: Module) {

        switch (module.productName.toUpperCase()) {
            case this._closedownService.workBench:
                this.siteVisitReasons = await this._siteService.getVisitReasons();
                if (this._closedownService.IsClosedownRedirectPermitted() && this.siteVisitReasons && this.siteVisitReasons.length > 0) {
                    if (this.siteVisitReasons[0].id != "") {
                        this.showWorkBench = true;
                        if (this._utilsService.isMobileDevice()) {
                            this._closedownService.directToClosedown(false);
                            this.reloadWhenBrowserClose();
                        } else {
                            this._closedownService.directToClosedown(false);
                        }
                    }
                }
                break;
            default:
                this._closedownService.appPlatformRedirect(module);
                break;
        }
        this._loaderService.hidePageLoading();
    }

    moduleOnClick(module: Module) {

        switch (module.productName.toUpperCase()) {
            case this._closedownService.workBench:
                this.goToWorkBench();
                break;
            default:
                this._closedownService.appPlatformRedirect(module);
                break;
        }

    }

    goToWorkBench() {
        let userCompanies: string[] = [];
        if (this.currentUser) {
            if (this.currentUser.companies) {
                this.currentUser.companies.forEach(company => {
                    if (company.companyType.toUpperCase() != CompanyType.SUBCON) {
                        userCompanies.push(company.referenceNumber);
                    }
                });
            }
            this._jobService.getJobsByCompanyRefNumber(undefined).subscribe(async jobs => {
                if (jobs && jobs.length > 0 && this.currentUser) {
                    await this._closedownService.setUserJobs(jobs);
                    var processFlow = await this._closedownService.getProcessFlow();
                    if (processFlow.siteCode != '' && processFlow.token) {
                        await this._closedownService.setWithFastLink(true);
                        processFlow.linkType = FastLinkType.Closedown;
                        await this._closedownService.setProcessFlow(processFlow);
                    }
                    this._closedownService.directToClosedown(false);

                }
            })
        }
    }

    initBrowserBackButton() {
        history.pushState(null, '');
        fromEvent(window, 'popstate').pipe(takeUntil(this.unsubscriberBackBtn)).subscribe((_) => {
            history.pushState(null, '');
        });
    }

    showModule(module: Module): boolean {
        let isShow = false;

        if (module.productName.toUpperCase() == this._closedownService.workBench) {
            isShow = this.showWorkBench;
        } else {
            isShow = true;
        }

        if (isShow) {
            this.showProductLabel = true;
        }

        return isShow;

    }

    isJobReason(): boolean {
        if (this.siteVisitReasons && this.siteVisitReasons.length > 0 && this.siteVisitReasons[0].id !== '') {
            return true;
        }
        return false;
    }

    isReactiveJob(job: Job) {
        return (job.jobType == JobType.REACTIVE_JOB) ? true : false;
    }

    isPPMJob(job: Job) {
        return (job.jobType == JobType.PPM_JOB) ? true : false;
    }

    async fastlinkOnClick(resourceId: string, jobId: string, subcontractorResourceId: string, calloutReferenceId: string, jobType: string) {
        if (this.currentUser) {
            var processFlow = await this._closedownService.getProcessFlow();
            var allocatedToUser = this.allocatedJobs.some(s => s.job.allocatedUserEmail.toLowerCase() == this.currentUser?.emailAddress.toLowerCase())
            processFlow.resource = resourceId;
            processFlow.jobId = jobId;
            var company = this.currentUser.companies.find(c => c.referenceNumber.toLowerCase() == resourceId.toLowerCase());
            if (company || allocatedToUser) {
                if (jobType == JobType.REACTIVE_JOB) {
                    if (company?.companyType == CompanyType.SELF) {
                        processFlow.url = btoa(`/#/Job/New/${jobId}`);
                    } else {
                        processFlow.linkType = FastLinkType.Closedown;
                        processFlow.url = btoa(`/#/FastClosedown/${jobId}/${calloutReferenceId}/${resourceId}/${subcontractorResourceId}`);
                    }

                } else if (jobType == JobType.PPM_JOB) {
                    if (company?.companyType == CompanyType.SELF) {
                        processFlow.url = btoa(`/#/Job/PPM/${jobId}`);
                    } else {
                        processFlow.linkType = FastLinkType.Closedown;
                        processFlow.url = btoa(`/#/PPMJob/${jobId}/${calloutReferenceId}/0/0/${subcontractorResourceId}`);
                    }
                }

                this._closedownService.setProcessFlow(processFlow);
                this._closedownService.directToClosedown(false, resourceId);
            }

        }
    }

}