
    <div id="login" fxLayout="column">
        <div fxLayout = "row">
            <div fxFlex = "3"></div>
            <div class="logo" fxFlex = "94">
                <img src="/assets/CityOnSite_Logo.png">
            </div> 
        </div>
        <form [formGroup]="loginUserFormGroup">
            <div fxLayout = "row">
                <div fxFlex = "100">
                    <div fxLayout="column" fxLayoutGap="0.5em">
                        <div fxLayout="row">
                            <div class="font-size-h1 color-city-blue">{{'loginuser_signin' | translate }}</div>
                        </div>
                        <div>
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label class="color-city-blue">{{'loginuser_enteremail_field' | translate }}</mat-label>
                                <input matInput 
                                placeholder="{{'loginuser_enteremail_field' | translate }}" 
                                formControlName="emailAddress" 
                                required 
                                id="input_emailaddress"
                                data-testid="input_email_address">
                                <mat-error *ngIf="hasError(loginUserFormGroup, 'emailAddress')">{{getError(loginUserFormGroup, 'emailAddress')}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="signup-user">
                                <a class="color-city-blue" id="btn_signupuser"  data-testid="btn_signup_user" (click)="signUpUser()">
                                    {{'loginuser_signup_user_prompt' | translate }} <br> {{'loginuser_signup_user_action' | translate }}</a>
                        </div>
                        <div class="divider"></div>
                        <div>
                            <button type="submit" 
                            id="btn_loginusercontinue" 
                            data-testid="btn_login_user_continue"
                            mat-raised-button 
                            (click)="continue()" 
                            [disabled]="disableSubmitButton()">
                                {{'loginuser_continue' | translate }} 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div fxLayout = "row" fxFlex = "100" *ngIf="isMutliLanguageEnable && languages.length >= 1">
            <mat-form-field appearance="outline" style="margin-top:3vh;" fxFlex = "100">
                <mat-select id="language" name="language"  [(value)]='language' 
                (selectionChange)="onLangSelect($event.value)">
                    <mat-option *ngFor="let lang of languages" [value]="lang.code" >{{lang.description}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="divider"></div>
        <div *ngIf="isWebBrowser()" fxLayout = "row" style="align-self: center;" class="app_store_badge">
            <div fxLayout="col"   >
                <img alt='Download on the App Store' style="height: 50px;padding-top: 9px;" src='/assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' (click)="installApple()">
            </div>
    
            <div fxLayout="col">
                <img alt='Get it on the Play Store' style="height: 60px; padding-top: -12px; " src='/assets/google-play-badge.png' (click)="installGoogle()">
            </div>
        </div>
        <div class="divider"></div>
        <div fxLayout = "row">
            <div fxFlex = "3"></div>
            <div class="logo" fxFlex = "94">
                <img src="/assets/CityDowntown.png">
            </div>
        </div>
        <div class="divider"></div>
        <!--TODO Hide when running as native app-->
        <div fxLayout = "row" class="version">
            <div fxLayout="col">
                {{'v' + version }}
            </div> 
        </div>
    </div>

